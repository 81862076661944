import React, { createContext, useContext, useState } from 'react';

const defaultMenuContext = {
  isMenuOpen: false, // Default state, menu is not open
  toggleMenu: () => {}, // No-op function, does nothing by default
};

const MenuContext = createContext(defaultMenuContext);

// Create a custom hook to use the context
export const useMenu = () => {
  return useContext(MenuContext);
};

// Create the context provider component
export const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const value = {
    isMenuOpen,
    toggleMenu,
  };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
